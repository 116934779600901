$platform-name: "nationalageingresearchinstitutelimited";

//
// Colours
//

// Brand colours
$_blue: #212492;
$_navy: #233383;
$_light-blue: #006fba;
$_lime: #69be28;
$_green: #00693c;
$_gold: #f7a400;
$_orange: #ef7d00;
$_burnt-orange: #c76d23;
$_red: #cb2f54;
$_teal: #00a2c9;
$_purple: #792f79;
$_pink: #ec607e;

$_enjoy-green: #33AA47;
$_enjoy-grey: #1A1A1A;
$_enjoy-yellow: #FFED00;
$_enjoy-lime: #B5CE52;

$_pitch-blue: #1c31a7;
$_pitch-purple: #5e5ca7;

// Brand gradients
$_navy-gradient--vertical: linear-gradient(180deg, $_navy 0%, #016ab4 100%);
$_navy-gradient--vertical--inverted: linear-gradient(
  180deg,
  #016ab4 0%,
  $_navy 100%
);
$_navy-gradient--horizontal: linear-gradient(90deg, $_navy 0%, #016ab4 100%);
$_lime-gradient--horizontal: linear-gradient(90deg, #21812a 0%, #9ac013 100%);
$_red-gradient--vertical: linear-gradient(
  180deg,
  $_red 0%,
  darken($_red, 15%) 100%
);
$_orange-gradient--vertical: linear-gradient(
  180deg,
  lighten($_orange, 15%) 0%,
  $_orange 100%
);
$_burnt-orange-gradient--vertical: linear-gradient(
  180deg,
  lighten($_burnt-orange, 15%) 0%,
  $_burnt-orange 100%
);
$_green-gradient--horizontal: linear-gradient(
  90deg,
  $_green 0%,
  lighten($_green, 10%) 100%
);

$brand-primary: $_light-blue;
$brand-secondary: $_green;
$brand-secondary-text-contrast-override: #fff;
$donate-colour: $_green;

// Create greyscale
$grey-dark: #4e4e4e;
$grey-dark: #292b2c;
$grey: #918784;
$grey-lightest: #f4f4f4;
$white: #fff;

//
// Layout
//

$container-max-width: 1180px;
$_wider-max-width: 1330px;

$border-colour: #bdbdbd;

// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px,
);

//
// Misc
//

$body-bg: $grey-lightest;
$date-ordinals-enabled: false;

//
// Logo
//

$logo: "logo.svg";
$logo-width: 320px;
$logo-height: 103px;

//
// Typography
//

$font-family-base: "Overpass", serif;
$font-size-base: 18px;
$font-size-large: 1.125em;
$font-size-small: 0.889em;

$font-weight-normal: 400;
$line-height-base: 1.6;
$font-weight-bold: 800;

// 1.125 Major Second scale
$font-size-h1: 1.802em;
$font-size-h2: 1.602em;
$font-size-h3: 1.424em;
$font-size-h4: 1.266em;
$font-size-h5: 1.125em;
$font-size-h6: 0.889em;

$headings-margin-top: 0.75em;
$headings-margin-bottom: 0.5em;
$headings-line-height: 1.2;
$headings-colour: $brand-primary;
$headings-text-transform: uppercase;
//$headings-letter-spacing: -0.05em;
$headings-font-weight: 500;


// Links
$link-decoration: underline;
$link-decoration-colour: rgba($brand-primary, 0.5);

//
// Buttons
//

$btn-padding-x: 1.5em;
$btn-padding-y: 0.65em;
$btn-box-shadow: 0.5em 0 rgba($white, 0.3) inset;
$btn-font-weight: 500;
// $btn-text-transform: uppercase;
//$btn-letter-spacing: $headings-letter-spacing;

//
// Social icons
//

$social-icons-header-background-colour: transparent;
$social-icons-header-colour: $brand-primary;
$social-icons-header-hover-colour: darken($brand-primary, 10%);

$header-social-icons-margin-right: 1em;

//
// Header search
//

$header-search-margin-right: 1em;
$header-search-input-max-width: 170px;
$header-search-input-margin-right: 0;
$header-search-input-height: 43px;
$header-search-input-border-colour: $brand-primary;
$header-search-input-background-colour: rgba($white, 0.5);
$header-search-button-height: $header-search-input-height;
$header-search-button-width: $header-search-input-height;

//
// Card
//

$card-border: 0;
$card-box-shadow: 0px 0.5em 1.5em rgba(0, 0, 0, 0.05);
$card-details-padding: 1.5em;
$card-heading-font-size: $font-size-h5;
$card-heading-text-transform: none;
$card-summary-font-size: $font-size-small;
$card-summary-colour: $grey-dark;
$card-footer-background-gradient: $_navy-gradient--horizontal;
$card-footer-colour: $white;
$card-heading-text-transform: none!important;


// Card hover state
$card-hover-box-shadow: 0px 0.75em 1em rgba(0, 0, 0, 0.1);
$card-hover-heading-colour: $_navy;
$card-hover-summary-colour: $card-summary-colour;

//
// Shop
//

$shop-enabled: false;

//
// Menu admin
//

$menu-admin-background-gradient: $_navy-gradient--vertical--inverted;
$menu-admin-contents-max-width: $_wider-max-width;
$menu-admin-font-size: 0.8em;
$menu-admin-link-colour: $white;
$menu-admin-login-link-enabled: false;
$menu-admin-manager-link-enabled: false;
$menu-admin-my-details-link-enabled: false;
$menu-admin-logout-link-enabled: false;

//
// Header
//

$header-background-colour: $white;

// $header-absolute: true;
$header-absolute-background: transparent; // Take care of this in bespoke
$header-absolute-breakpoint: map-get($breakpoints, lg);

// Header content
$header-content-max-width: $_wider-max-width;
$header-content-margin-top: 0;
$header-content-margin-bottom: 0;
$header-content-padding-top: 0.75em;
$header-content-padding-bottom: 0.75em;

//
// Navigation
//

$nav-background-colour: $_blue; // Gradient added in bespoke
$nav-text-transform: none;

// Top level items
$nav-top-level-item-padding: 8px 15px;
$nav-top-level-item-colour: $white;
$nav-top-level-item-font-size: $font-size-small;
$nav-top-level-chevrons-enabled: true;
$nav-top-level-chevrons-colour: rgba($white, 0.6);
$nav-top-level-item-hover-background-colour: $brand-primary;
$nav-top-level-first-item-home-icon: true;

// Submenus
$nav-submenu-background-colour: $brand-primary;
$nav-submenu-item-padding: 8px 15px;
$nav-submenu-item-colour: #fff;
$nav-submenu-item-font-size: 0.8em;

// Nav normal
$nav-normal-max-width: 100%;
$nav-normal-contents-max-width: $_wider-max-width;
$nav-normal-margin-bottom: 0;

//
// Carousel
//

$carousel-max-width: 100%;
$carousel-contents-max-width: $_wider-max-width;
$carousel-details-max-width: 580px;
$carousel-details-padding: 0; // See bespoke
$carousel-details-margin-y: 30px;
$carousel-details-background-colour: rgba($white, 0.85);
$carousel-heading-colour: $white;
$carousel-heading-font-size: $font-size-h5;
$carousel-heading-text-transform: none;
$carousel-summary-colour: $card-summary-colour;
$carousel-read-more-background-colour: transparent;
$carousel-read-more-colour: $brand-secondary;
$carousel-read-more-font-size: 14px;

// Carousel controls
$carousel-controls-button-gap: 4px;
$carousel-controls-button-background-colour: $brand-primary;

// Carousel buttons dots
$carousel-dots-position-x: right;
$carousel-dots-padding: 40px 84px;

//
// Home intro
//

$home-intro-background-colour: $_green;
$home-intro-background-gradient: $_green-gradient--horizontal;
$home-intro-content-max-width: $_wider-max-width;
$home-intro-margin-top: 0;
$home-intro-text-align: left;
$home-intro-text-transform: none;
$home-intro-font-weight: 400;
$home-intro-font-size: 1em;
$home-intro-mobile-padding-y: 3rem;

//
// Home features
//

$home-features-background-colour: transparent;
$home-features-max-width: $_wider-max-width;
$home-features-cards-entry-animation-prefab: 1;

//
// Impact stats
//

$impact-stats-padding-y: 4em;
$impact-stats-background-gradient: $_burnt-orange-gradient--vertical;
$impact-stats-heading-font-size: $font-size-h2;
$impact-stats-heading-margin-bottom: 1em;

//
// Home feeds
//

$home-feeds-cards-entry-animation-prefab: 1;
$home-feeds-cards-entry-animation-stagger-count: 4;

$feeds-title-font-size: 1em;
$feeds-title-colour: $border-colour;

//
// Context container
//

$context-container-background-colour: $white;

// Donors list
$donors-list-enabled: false;

//
// Sidebar
//

$sidebar-adjacent-width: 240px;
$sidebar-adjacent-gap: 3em;

// Associated lists
$associated-most-read-enabled: false;
$associated-max-number-of-posts: 3;
$associated-list-heading-font-size: $font-size-h6;
$associated-item-headings-text-transform: none;


//
// Footer
//

$footer-boxes-count: 5;
$footer-prefab: 1;
$footer-background-colour: darken($_navy, 10%);
$footer-contents-max-width: $_wider-max-width;
$footer-colour: #fff;
$footer-link-colour: #fff;
$footer-font-size: $font-size-small;

// Newsletter
$newsletter-background-gradient: $_navy-gradient--horizontal;

//
// Quick giving panel
//

$quick-giving-background-colour: $donate-colour;
$quick-giving-donation-amount-background-colour: transparent;
$quick-giving-donation-amount-figure-colour: $white;
$quick-giving-donation-amount-border-width: 1px;
$quick-giving-donation-amount-border-colour: $white;
$quick-giving-donation-amount-selected-figure-colour: $_burnt-orange;

//
// Home quick giving panel
//

$home-quick-giving-padding-y: 4em;
$home-quick-giving-content-max-width: $container-max-width;
$home-quick-giving-colour: text-contrast($donate-colour);
$home-quick-giving-donation-amount-selected-figure-colour: $_burnt-orange;
$home-quick-giving-donate-btn-background-colour: $_burnt-orange;
$home-quick-giving-donate-btn-colour: $white;

//
// Blockquote
//

$blockquote-background-colour: white;
$blockquote-box-shadow: $btn-box-shadow;
$blockquote-text-align: left;
$blockquote-font-size: $font-size-h4;
$blockquote-font-weight: 400;
$blockquote-colour: $brand-primary;

//
// Subsite
//

$subsite-enabled: true;
$subsite-hide-main-tagline: true;
$subsite-hide-header-search: true;
// $subsite-hide-main-cta: true;

//
// Cookie consent
//

$cookie-consent-font-family: inherit;
