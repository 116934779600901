h1, h2, .postCreateForm legend, .inMemoryCreateForm legend, .post .donationFormItems .contentBlockWrapper legend, h3, .basket .total, h4, h5, .topFundraisersWrapper > strong, #makeComment, .appealInteractions .tabbedListingTitle, .eventLabel, .basket .vat, h6 {
 // letter-spacing: normal;
}

// Nudge left padding to account for inset box shadow
.cta-button,
.button,
button,
.readMore,
.addToCalender,
.mainCallToAction .cta-button:not([class*="donate"]) {
  padding-left: calc(#{$btn-padding-x} + 0.5em);
}

// Remove text underline from some links
h1,
h2,
h3,
h4,
h5,
h6,
.menuMain,
.socialIcons {
  a {
    text-decoration: none;
  }
}

body {
  overflow-x: hidden; // To fix certain psuedo element patterns
}

//
// Menu admin
//

#menuAdmin {
  li {
    padding: 0;
    margin: 0;
  }

  a {
    display: block;
    text-decoration: none;
    padding: 0.5em 0.75em;

    &:hover {
      background-color: $brand-primary;
    }
  }

  // External links
  a[target="_blank"]:after {
    content: "\f08e";
    font-family: "FontAwesome";
    margin-left: 0.25em;
    font-size: 0.75em;
  }
}

// When we're on a subsite, only show the 'NARI main site' link
// .subsite #menuAdmin a:not([href="/"]) {
//   display: none;
// }

// // When we're on the main site, hide the 'NARI main site' link
// body:not(.subsite) #menuAdmin a[href="/"] {
//   display: none;
// }

// Header fixes
@if $header-absolute == true {
  @media (min-width: $header-absolute-breakpoint + 1px) {
    body:not(.subsite).page-has-banner {
      .pageHeader {
        // overflow: hidden;
      }

      .headerContent {
        position: relative;

        &:before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba($white, 0.7);
          width: 200vw;
          transform: translateX(-50%);
          z-index: -1;
        }
      }
    }
  }
}

// Nav gradient
.menuMain {
  background: $_navy-gradient--vertical--inverted;
}

@media (min-width: $nav-breakpoint + 1px) {
  .menuMain ul.subMenu {
    z-index: 4;
  }

  body:not(.subsite).page-no-banner .menuMain {
    background-color: $_blue;
  }
}

.contentBlockWrapper {
  position: relative;
  z-index: 3;
}

// Nav item letter spacing
// .menuMain a {
//   letter-spacing: -0.0375em;
// }

//
// Carousel
//

.carousel {
  z-index: 1; // Above home intro
}

.carouselSlideHeading {

}

@media (min-width: $carousel-breakpoint + 1px) {
  .carouselSlideHeading {
    padding: 20px 30px;
    margin: 0;
    background-color: $_green;
  }

  .carouselSlide:nth-child(2n) {
    .carouselSlideHeading {
      background-color: $_burnt-orange;
    }

    .carouselSlideReadMore {
      color: $_burnt-orange;
    }
  }

  .carouselSlide:nth-child(3n) {
    .carouselSlideHeading {
      background-color: $_teal;
    }

    .carouselSlideReadMore {
      color: $_teal;
    }
  }

  .carouselSlideSummary {
    padding: 20px 30px;
  }

  .carouselControls {
    max-width: $carousel-contents-max-width;
    margin: 0 auto;
  }

  .carouselButtons {
    max-width: calc(
      #{$carousel-contents-max-width} - (#{$carousel-controls-button-size * 2}) +
        #{$carousel-controls-button-gap}
    );
    margin: 0 auto;
    padding-right: 120px;
  }
}

.carouselControls {
  padding: 30px $site-bleed;
}

.carouselSlideReadMore {
  padding: 0;
  box-shadow: none;

  &:hover {
    background-color: transparent;
    color: $carousel-read-more-colour;
  }

  @media (max-width: $carousel-breakpoint) {
    color: $carousel-mobile-summary-colour;
  }
}

// Curve on inner page banners
.carousel:not(.hasCaption) .carouselSlide {
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: url($assets-path + "bottom-curve--grey.svg");
    background-repeat: no-repeat;
    background-size: 102% auto;
    background-position: bottom -2px center;
  }
}

@media (max-width: $carousel-breakpoint) {
  .carouselButtons {
    display: none;
  }
}

//
// Home intro
//

.homeIntro > * {
  max-width: 900px;
  color: inherit;
  font-weight: inherit;
 // letter-spacing: inherit;
}

// Org name
.homeIntro .org-name {
  font-size: 1.6em;
  opacity: 0.8;
  display: block;
  margin-bottom: 0.5em;

  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    width: 40px;
    height: 40px;
    background-image: url($assets-path + "icon--aus.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }

  @media (max-width: 500px) {
    &:before {
      display: block;
      margin: 0 0 5px;
    }
  }
}

// The actual home intro text
.homeIntro h2 {
  line-height: 1.2;
  font-size: 2.8em;
}

// Bottom curve
.homeIntroWrapper {
  position: relative;
  @media (min-width: map-get($breakpoints, md) + 1px) {
    padding-bottom: calc(#{$home-intro-padding-y} + 2em);
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: url($assets-path + "bottom-curve--grey.svg");
    background-repeat: no-repeat;
    background-size: 102% auto;
    background-position: bottom -2px center;
    pointer-events: none;
  }
}

//
// Impact stats
//

.homefeaturecategory-homeboximpactstats {
  // curves
  position: relative;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 102% auto;
  }

  &:before {
    background-position: top -2px center;
    background-image: url($assets-path + "top-curve--grey.svg");
  }

  &:after {
    background-position: bottom -2px center;
    background-image: url($assets-path + "bottom-curve--white.svg");
  }

  td:first-child:before {
    content: "";
    width: 80px;
    height: 80px;
    display: block;
    margin: 0 auto 10px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  tr:first-child td:first-child:before {
    background-image: url($assets-path + "icon--mag.svg");
  }

  tr:nth-child(2) td:first-child:before {
    background-image: url($assets-path + "icon--radio.svg");
  }

  tr:nth-child(3) td:first-child:before {
    background-image: url($assets-path + "icon--book.svg");
  }
}

//
// Card
//

// Reveal and style publish date on news
.listedItem.module-news {
  .postFooterWrapper,
  .publishDatesWrapper {
    display: block !important;
  }
}

//
// Sidebar
//

.associated:not(.associatedBox) {
  font-size: $font-size-small;

  .associatedListing {
    background: $white;
    padding: 5px 15px;
  }

  > h2 {
    padding: 10px 15px;
    background-color: $brand-primary;
    color: text-contrast($brand-primary);
    margin: 0;
  }

  &.associatedRelatedPosts > h2 {
    background-color: $_burnt-orange;
  }

  li h3 {
    font-size: 1em;
  }

  li {
    border-bottom: 0;
  }

  li:first-child {
    border-top: 0;
  }
}

//
// Quick giving
//

.homefeaturecategory-homeboxquickgiving {
  // Curve
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: url($assets-path + "top-curve--white.svg");
    background-repeat: no-repeat;
    background-size: 102% auto;
    background-position: top -2px center;
  }

  h3 {
    color: inherit;
  }
}

.homepage-quickgiving--copy {
  max-width: 480px;
}

.quickGivingPanel .button {
  background-color: $_burnt-orange;
}

//
// Footer
//

.pageFooter {
  p {
    margin-bottom: 0;
  }

  .socialIcons {
    margin-top: 15px;
  }

  @media (max-width: map-get($breakpoints, sm)) {
    text-align: center;

    .socialIcons {
      justify-content: center;
    }
  }

  .map-link {
    display: flex;

    &:after {
      font-family: "FontAwesome";
      content: "\f08e";
      margin-left: 4px;
    }
  }
}

.footerBox5 {
  text-align: center;
  font-size: 0.9em;

  ul {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  a {
    display: block;
    margin: 5px;
  }

  > span {
    opacity: 0.5;
  }
}

body:not(.userLoggedIn) .pageFooter {
  a[href*="/my-details"],
  a[href*="/logout"] {
    display: none;
  }
}

body.userLoggedIn .pageFooter a[href*="/login"] {
  display: none;
}

//
// Internal group sign up forms, eg. at top of news listing
//

.distributionForm > div {
  background: $newsletter-background-gradient;
  color: $newsletter-colour;
  padding: 1.5em;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .formQuestion {
    flex-grow: 1;
    margin-right: $gap-width;
  }

  .groupSignUpBtn {
    flex-grow: 0;
    height: 38px;
    background-color: $brand-secondary;
    padding-top: 0;
    padding-bottom: 0;
    margin: 19px 0 0; // Align with fields

    &:hover {
      background-color: darken($brand-secondary, 10%);
    }
  }

  @media (max-width: map-get($breakpoints, md)) {
    flex-direction: column;

    .formQuestion {
      width: 100%;
      margin: 0 0 10px;
    }

    .groupSignUpBtn {
      margin: 0;
    }
  }
}

//
// Flourishes
//

// Full width underline
.feedsTitle,
h1.title {
  @include heading-underline(
    $colour: $border-colour,
    $width: 100%,
    $height: 1px
  );
}

// PDF downloads (when inserted correctly)
.downloadTrack {
  display: flex;
  background-color: $_light-blue;
  text-decoration: none;
  color: white!important;
  align-items: center;
  &:before {
    content: "\f019";
    font-family: "FontAwesome";
    margin-right: 1.5em;
    font-weight: normal;
    color: white;
    padding: 1em;
    background-color: rgba(255,255,255,.25);
  }
  &:hover {
    &:before {
      background-color: $_blue;
    }
  }
}

//
// Card tags
//

@mixin card-tag($label) {
  .listedPostText h2:before {
    content: $label;
    display: block;
    font-size: 0.85rem;
    margin-bottom: 8px;
    color: lighten($grey, 10%);
  }
}

// .listedItem {
//   &.PostCategory_dementia {
//     @include card-tag("Dementia");
//   }

//   &.PostCategory_aboriginal-and-torres-strait-islanders {
//     @include card-tag("Aboriginal and Torres Strait Islanders");
//   }

//   &.PostCategory_aged-care {
//     @include card-tag("Aged care");
//   }

//   &.PostCategory_cultural-diversity {
//     @include card-tag("Cultural diversity");
//   }
// }

// Reveal publish date on research listed items
.listedItem.PostCategory_research:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
  .postFooterWrapper,
  p.publishDatesWrapper {
    display: block;
  }
}

.menuMain {
  z-index: 4;
}

#menuAdmin li[class*='marc'] a {
  background: $_burnt-orange-gradient--vertical;
  color: $white;
  &:hover { background: $_burnt-orange; }
}

#menuAdmin li[class*='itch'] a {
  background: linear-gradient(180deg, $_pitch-purple 0%, $_pitch-blue 100%);
  color: $white;
  &:hover { background: $_pitch-blue; }
}

#menuAdmin li[class*='njoy'] a {
  background: linear-gradient(180deg, darken($_enjoy-lime, 5%) 0%, darken($_enjoy-green, 5%) 100%);
  color: $white;
  &:hover { background: darken($_enjoy-lime, 5%); }
}

li.hasSubmenu>a:after {
  font-size: 0.8em;
}

.subsite:not(.homepage) .headerTextSubsite {
  display: none;
}


.listedPost.PostCategory_research .postFooterWrapper {
  display: none !important;
}

.post .donationFormItems .contentBlockWrapper > section {
  background-color: $white;
}

// Logo page - overall
body[class*='marc-partner'] {
  background-color: $white;
  // Sidebar disabled
  .post .contentBlock .postAside {
    display: none;
  }
  // Content width
  .post .contentBlockWrapper, .post .headerText .title {
    max-width: 900px;
  }
  // Logo container
  .logoContainer {
    width: calc(100% + #{$spacer * 2});
    margin-left: -$spacer !important;
    margin-right: -$spacer !important;
    margin: #{$spacer * 2} 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    > * {
      margin: $spacer;
    }

    img {
      max-width: 180px;
      max-height: 120px;
      object-fit: contain;
      @media (max-width: map-get($breakpoints, md)) {
        max-width: 120px;
        max-height: 100px;
      }
    }
  }
}

// DES-4938
.subsite .homeFeed.subsiteFeed {
  padding-top: 2.5rem;
}

// DES-5388
body.PostSystemCategory_hide-calendar-button .addToCalender {
  display: none !important;
}

// Stuff from /manager/settings/css
.formQuestion.displayName,
#appealComments {
  display: none !important;
}

.post .appealActionsWrapper {
  z-index: 4;
}

// https://raisingit.atlassian.net/browse/DES-5742
#faqImage {
  display: none;
}

.homeFeatures .homeFeature h2 {
 // font-weight: 400;
}

h3.feedTitle {
 // font-weight: 400;
  text-transform: none!important;
}

blockquote {
  border-radius: 0 20px;
  position: relative;
  padding: 2rem 5rem;
  box-shadow: 0px 0.5em 1.5em rgba(0, 0, 0, 0.05);
  @media (max-width: map-get($breakpoints, sm)) {
    padding: $spacer * 3;
  }
  text-align: center;
  &:before, &:after {
    line-height: 1;
    font-size: 2.5em;
    position: absolute;
    font-family: FontAwesome;
    color: gainsboro;
  }
  &:before {
    content: "\f10d";
    left: $spacer * 2;
    top: $spacer * 1.5;
    @media (max-width: map-get($breakpoints, sm)) {
      left: $spacer;
      top: $spacer;
    }
  }
  &:after {
    content: "\f10e";
    right: $spacer * 2;
    bottom: $spacer * 1.5;
    @media (max-width: map-get($breakpoints, sm)) {
      right: $spacer;
      bottom: $spacer;
    }
  }
}

.carouselSlideReadMore {
  text-transform: uppercase;
}
.associated > h2 {
  text-transform: none;
}
h3.eventLabel {
    text-transform: none;
}

body.PostCategory_hide-latest {
  section.associated.associatedLatestPosts {
    display: none;
  }
}