//
// Home features
//

// Choose your overall home feature cards layout
.homeFeatures {
  @include cards-layout-prefab($number-of-cards: 3, $prefab: 1);

  // Choose your card style for home feature cards
  .homeFeature {
    @include card;
    @include card-basic;
  }
}

// Alternatively, you can target specific features rather than styling them all
// If you do this, you will need to target them ALL uniquely, so:
// .homeBox1 {...}, .homeBox2 {...}, .homeBox3 {...} etc.

//
// Home feeds
//

@include home-feeds-prefab($number-of-feeds: 2, $prefab: 1);

.feedList {
  @include cards-layout-prefab($number-of-cards: 4, $prefab: 7);
}

.feedItem:first-child {
  @include card (
    $card-box-shadow: 0,
    $card-details-padding: 0 1.5em,
    $card-heading-font-size: $font-size-h3,
    $card-summary-font-size: 1em,
    $card-hover-box-shadow: 0
  );
  @include card-side-by-side (
    $card-side-by-side-image-width: 59%
  );
}

.feedItem:not(:first-child) {
  @include card (
    $card-box-shadow: 0,
    $card-details-padding: 1em 0,
    $card-hover-box-shadow: 0
  );
  @include card-basic;
}

//
// Listed posts
//

// Choose your card style for listed post cards
// (not products, search result or embedded map item)
.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
  @include card;
  @include card-basic;
}

//
// Listed products
//

// If these are exactly the same as your regular 'listed posts' above
// you can remove the ':not(.listedProduct)' selector above and squash this
// together into more efficient CSS output
.listedProduct {
  @include card;
  @include card-basic;
}
